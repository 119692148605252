/* AboutUsPage.css */
.aboutUsPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-top: 120px;
}

.aboutUsContent {
  text-align: justify;
  max-width: 800px;
  margin-bottom: 20px;
}

.aboutUsImageContainer {
  width: 100%;
  max-width: 800px;
}

.aboutUsImage {
  width: 100%;
  max-height: 400px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.aboutUsTitle {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #851016;
}

.aboutUsDescription {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #000;
}

/* Unique CSS for About Us */
.aboutUsDescription::before {
  content: '';
  position: absolute;
  top: 120px;
  right: 30px;
  width: 40%;
  height: 50%;
  background-image: url('../../../Assets/logos/parkville\ logo.jpg'); /* Replace with your actual image URL */
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  z-index: -1;
}

.bookAboutUsButton {
  background-color: #851016;
  color: #fff;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.bookAboutUsButton:hover {
  background-color: #6c0d12;
}

.buttonLink {
  text-decoration: none;
  color: inherit;
}


/* Media queries for responsiveness */
@media screen and (min-width: 768px) {
  .aboutUsPageContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .aboutUsContent {
    max-width: 45%;
  }

  .aboutUsImageContainer {
    max-width: 45%;
  }
}

@media screen and (max-width: 767px) {
  .aboutUsContent,
  .aboutUsImageContainer {
    max-width: 100%;
  }

  .aboutUsDescription::before {
    width: 90%;
    height: 20%;
    top: 600px;
    right: 5px;
  }
}
