
  .why-choose-usSection {
    position: relative;
    padding: 10px 0;
    background-color:#851016;
  }
  
  
  .choose-us-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .choose-us-container h2 {
    text-align: center;
    margin-bottom: 10px;
    color: #FFD7D7;
    font-size: 1.4rem;
  }
  
  .counters {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .counter {
    text-align: center;
    margin: 0 10px;
  }
  
  .counter-value {
    font-size: 1.4rem;
    color: #ffffff; /* Adjust color as desired */
    animation: pulse 1s infinite alternate; /* Add pulsing animation */
  }

  .counter-label {
    font-size: 1rem;
    color: #FFD7D7;
    position: relative;
  }
  
  .counter-label::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 2px;
    background-color: #851016;
  }
  
  
  .reasons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 50px;
    animation: fadeUp 1s ease-in-out;
    padding: 5px;
  }
  
  .reason {
    text-align: center;
  }
  
  .icon {
    font-size: 1.6rem;
    margin-bottom: 2px;
    color: #ffffff;
  }
  
  .reason h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: #FFD7D7;
  }
  
  .reason p {
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.5;
    text-align: justify;
  }
  
  /* Media Queries for Small Devices (e.g., smartphones) */
  @media only screen and (max-width: 768px) {
    .choose-us-container {
      max-width: 100%;
    }
    .choose-us-container h2 {
      margin-bottom: 15px;
      font-size: 1.2rem;
    }
    .reason h3 {
      font-size: 1rem;
    }
    
    .reason p {
      color: #ffffff;
      font-size: 0.8rem;
      text-align: center;
    }
    .reasons {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
    }
    .counter-label {
        font-size: 12px;
      }
      .counter-value {
        font-size: 1.4rem;
      }
      .background-image {
        width: 80%; /* Adjust the width as needed */
        max-width: 320px; /* Set a maximum width for smaller devices */
        height: 20%; /* Maintain aspect ratio */
      
      }
  }
  
  /* Media Queries for Medium Devices (e.g., tablets) */
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .reasons {
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 30px;
    }
    .counter-label {
        font-size: 14px;
      }
  }
  
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
  