/* PharmacyPage.css */
.pharmacyPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-top: 120px;
}

.pharmacyContent {
  text-align: justify;
  max-width: 600px;
  margin-bottom: 20px;
}

.pharmacyImageContainer {
  width: 100%;
  max-width: 600px;
}

.pharmacyImage {
  width: 100%;
  max-height: 400px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.pharmacyTitle {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #851016;
}

.pharmacyDescription {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
}

/* Media queries for responsiveness */
@media screen and (min-width: 768px) {
  .pharmacyPageContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .pharmacyContent {
    max-width: 45%; /* Adjust width for content on medium screens */
  }

  .pharmacyImageContainer {
    max-width: 45%; /* Adjust width for image on medium screens */
  }
}

@media screen and (max-width: 767px) {
  .pharmacyContent,
  .pharmacyImageContainer {
    max-width: 100%; /* Set width to 100% on small screens */
  }
  
  .pharmacyTitle {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  .pharmacyDescription {
    font-size: 1rem;
    line-height: 1.5;
  }
}
