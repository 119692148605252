body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Ensure any parent containers of .hero-section also have 100% width */
.parent-container {
  width: 100%;
}

/* Remove any default padding/margin */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgba(236, 197, 192, 0.7);
}

.slider-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
  z-index: 1;
}

/* Add !important to override any conflicting rules */
.slick-slider {
  margin: 0 !important;
}

.slider-image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 1s ease-in-out;
}

.slick-prev,
.slick-next {
  font-size: 24px;
  color: #fff;
  z-index: 3;
}

.slick-prev {
  left: 2%;
}

.slick-next {
  right: 2%;
}

.slick-prev:hover,
.slick-next:hover {
  color: #f3930b;
}

.hero-text {
  position: absolute;
  top: 15%;
  max-width: 50%;
  left: 30px;
  /* right: 15px; */
  transform: translateY(100%);
  text-align: left;
  opacity: 0;
  animation: slideIn 1s ease-in-out forwards;
  z-index: 2;
  line-height: 1;
}

/* Animation keyframes */
@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.hero-text h1 {
  color:#ffffff;
  font-size: 42px;
  line-height: 1;
}

.green-text {
  color: #FFD7D7;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  line-height: 1;
}

.orange-text {
  color: #ec9701;
  font-size: 18px;
}

.red-text {
  color: #eb1b2b;
  font-size: 18px;
}

.hero-button2 {
  display: inline-block;
  padding: 12px 24px;
  background-color: #851016;
  color: #fff;
  text-decoration: none;
  margin-top: 2px;
  border-radius: 5px;
  z-index: 1;
  transition: background-color 0.3s, transform 0.3s;
  overflow: hidden;
  clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
  font-size: 16px;
}

.hero-button1:hover {
  background-color: #f3930b;
  transform: scale(1.05);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .hero-text {
    font-size: 18px;
    margin-top: 30px;
  }

  .hero-section {
    height: 80vh;
  }

  .hero-text h1 {
    font-size: 36px;
  }

  .green-text {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 80vh;
  }

  .hero-text h1 {
    font-size: 24px;
  }

  .slider-image {
    height: 90vh;
  }

  .hero-text {
    font-size: 18px;
    top: 20%;
    max-width: 60%;
    left: 15px;
  }

  .green-text {
    font-size: 12px;
  }

  .hero-button2 {
    padding: 10px 16px;
    font-size: 14px;
  }
}
