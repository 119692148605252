/* AboutUs.css */

.about-us-section {
  background-color: #f9f9f9;
  padding: 40px 0;
  text-align: center;
}

.about-us-card {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: row-reverse; /* Display text before image */
}

.about-us-text {
  flex: 1;
  padding: 20px;
  text-align: left;
  position: relative; /* Required for positioning the background image */
}

.about-us-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../Assets/logos/parkville\ logo.jpg'); /* Replace with your actual image URL */
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  z-index: 1;
}

.about-us-text h2 {
  font-size: 32px;
  color: #851016;
  margin-bottom: 10px;
}

.about-us-text p {
  color: #000;
  margin-bottom: 20px;
  font-size: 1.2rem;
  line-height: 1.6;
}

.read-more-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #851016;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
  position: relative;
  overflow: hidden;
  clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
}

.read-more-button:hover {
  background-color: #006a2e;
}

.about-us-image {
  flex: 1;
  text-align: center;
}

.about-us-image img {
  max-width: 100%;
  height: auto;
}

/* Media Queries */
@media (max-width: 767px) {
  .about-us-card {
    flex-direction: column;
  }

  .about-us-text {
    text-align: left;
  }

  .about-us-text h2 {
    font-size: 20px;
  }

  .about-us-text p {
    font-size: 16px;
  }

  .about-us-image {
    text-align: center;
    margin-top: 20px;
  }
  .about-us-text::before {
    width: 100%;
    height: 70%;
    top: 50px;
   

  }
}
