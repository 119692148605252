/* OurServices.css */
.servicesContainer {
    text-align: center;
    padding: 10px 2px;
  }
  
  .servicesTitle {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
    color: #851016;
  }
  
  .servicesTitle.fade-up {
    opacity: 1;
    transform: translateY(0);
  }
  .servicesTitle p {
    color: #000;
    text-align: justify;
    padding: 5px;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .services {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .serviceCard {
    background-color: #f4f4f4; /* Light background color */
    border: 1px solid #851016; /* Solid border color */
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    margin: 20px;
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
    width: 350px;
    
  }
  
  .serviceCard.fade-up {
    opacity: 1;
    transform: translateY(0);
  }
  
  .serviceImage {
    width: 100%;
    height: 200px;
    border-bottom: 0.5px solid #851016;
    margin-bottom: 10px;
    padding: 0px;
  }
  
  .serviceDetails h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
    color: #851016;
  }
  
  .serviceDetails p {
    color: #000;
    text-align: justify;
    padding: 5px;
    font-size: 1rem;
    line-height: 1.5;
  }
  .find-outMoreButton {
    background-color: #851016;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    /* margin-top: 10px; */
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
  }
  
  .find-outMoreButton:hover:before {
    transform: translateY(-100%);
  }
  
  .find-outMoreButton:hover {
    background-color: #F2CFD5;
    color: #000;
  }
  
  /* Responsive Styling */
  @media screen and (max-width: 768px) {
    /* .servicesContainer {
      text-align: center;
      padding: 10px 2px;
    } */
    
    .servicesTitle {
      font-size: 1.4rem;
      margin-bottom: 10px;

    }
    
    .servicesTitle p {
      font-size: 1rem;
    }
    .serviceCard {
      width: calc(100% - 40px);
      margin: 20px 0;
    }
    .serviceDetails h3 {
      font-size: 1rem;
      margin-bottom: 2px;
    }
    .serviceDetails p {
      font-size: 0.9rem;
    }
    .find-outMoreButton {
      padding: 3px 8px;
      font-size: 0.8rem;
    }
    .serviceImage {
      margin-bottom: 5px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .servicesTitle {
      font-size: 1rem;
    }
  
    .serviceCard {
      padding: 0px;
    }
  }
  