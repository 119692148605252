/* MinorProceduresPage.css */
.minorProceduresPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin-top: 120px;
  }
  
  .minorProceduresContent {
    text-align: justify;
    max-width: 600px;
    margin-bottom: 20px;
  }
  
  .minorProceduresImageContainer {
    width: 100%;
    max-width: 600px;
  }
  
  .minorProceduresImage {
    width: 100%;
    max-height: 400px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .minorProceduresTitle {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #851016;
  }
  
  .minorProceduresDescription {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #000;
  }
  
  .bookAppointmentButton {
    background-color: #851016;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration-style: none;
    transition: background-color 0.3s ease;
  }
  .bookAppointmentButton a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent */
  }
  
  
  .bookAppointmentButton:hover {
    color: #6c0d12;
    background-color: #FFD7D7;
  }
  
  /* Media queries for responsiveness */
  @media screen and (min-width: 768px) {
    .minorProceduresPageContainer {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .minorProceduresContent {
      max-width: 45%; /* Adjust width for content on medium screens */
    }
  
    .minorProceduresImageContainer {
      max-width: 45%; /* Adjust width for image on medium screens */
    }
  }
  
  @media screen and (max-width: 767px) {
    .minorProceduresContent,
    .minorProceduresImageContainer {
      max-width: 100%; /* Set width to 100% on small screens */
    }
    .minorProceduresTitle {
      font-size: 1.4rem;
      margin-bottom: 10px;;
    }
    
    .minorProceduresDescription {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  