/* AppointmentForm.css */
.appointment-formContainer {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.form-group {
    margin-bottom: 15px;
}
.appointment-formContainer::before {
    content: '';
    position: absolute;
    top: 350px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../Assets/logos/parkville\ logo.jpg'); /* Replace with your actual image URL */
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
  }
label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
}

.input-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-group {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 10px;
    align-items: center;
}

.checkbox-group input {
    margin-right: 8px;
}

textarea {
    width: 100%;
    resize: vertical;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 5px;
}

button {
    background-color: #851016;
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #128c7e;
}

/* Media Queries for Responsiveness */
@media only screen and (max-width: 600px) {
    .appointment-formContainer {
        padding: 15px;
    }

    input,
    select,
    textarea,
    button {
        padding: 8px;
    }

    .checkbox-group input {
        margin-right: 0;
        margin-top: 5px;
    }
    .appointment-formContainer::before {
        top: 600px;
        width: 100%;
        height: 40%;
      }
}
