.contactPage {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center; /* Align items horizontally centered */
  padding: 20px;
  margin-top: 150px;
}

.contactDetails,
.location,
.ContactwhatsappButton {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center; /* Align items horizontally centered */
}

.contactPage h2 {
  color: #333;
  margin-bottom: 10px;
}

.info {
  display: flex;
  align-items: center;
}

.icon10 {
  margin-right: 10px;
  color: #851016;
  font-size: 18px;
}
.icon11 {
  margin-right: 10px;
  color:#25d366;
  font-size: 18px;
}

.contactPage p {
  color: #666;
  margin: 0;
}

.contactPage button {
  background-color:  #851016;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.contactPage button:hover {
  background-color: #128c7e;
}

.contactPage button a {
  text-decoration: none;
  color: inherit;
}

/* Media queries for small devices */
@media screen and (max-width: 768px) {
  .contactPage {
    padding: 10px;
  }

  .contactDetails,
  .location,
  .ContactwhatsappButton {
    gap: 10px;
  }

  .icon10 {
    font-size: 16px;
  }

  .contactPage button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

/* Media queries for medium devices */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contactPage {
    padding: 15px;
  }

  .icon10 {
    font-size: 16px;
  }

  .contactPage button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
