/* WellBabyClinicPage.css */
.wellBabyPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin-top: 120px;
  }
  
  .wellBabyContent {
    text-align: justify;
    max-width: 600px;
    margin-bottom: 20px;
  }
  
  .wellBabyImageContainer {
    width: 100%;
    max-width: 600px;
  }
  
  .wellBabyImage {
    width: 100%;
    max-height: 400px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .wellBabyTitle {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #851016;
  }
  
  .wellBabyDescription {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #000;
  }
  /* Unique CSS for Well Baby Clinic */
  .wellBabyDescription::before {
    content: '';
    position: absolute;
    top: 250;
    right: 30;
    width: 40%;
    height: 50%;
    background-image: url('../../../../Assets//logos/parkville\ logo.jpg'); /* Replace with your actual image URL */
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 1;
  }
  
  /* Media queries for responsiveness */
  @media screen and (min-width: 768px) {
    .wellBabyPageContainer {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .wellBabyContent {
      max-width: 45%; /* Adjust width for content on medium screens */
    }
  
    .wellBabyImageContainer {
      max-width: 45%; /* Adjust width for image on medium screens */
    }
    

  }
  
  @media screen and (max-width: 767px) {
    .wellBabyContent,
    .wellBabyImageContainer {
      max-width: 100%; /* Set width to 100% on small screens */
    }
    .wellBabyDescription::before {
        width: 90%;
        height: 20%;
        top: 400px;
        right: 5;

        }
        .wellBabyTitle {
          font-size: 1.4rem;
          margin-bottom: 10px;;
        }
        
        .wellBabyDescription {
          font-size: 1rem;
          line-height: 1.5;
        }

  }
  
  
  