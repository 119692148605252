/* GeneralConsultationPage.css */
.consultationPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin-top: 120px;
  }
  
  .consultationContent {
    text-align: justify;
    max-width: 600px;
    margin-bottom: 20px;
  }
  
  .consultationImageContainer {
    width: 100%;
    max-width: 600px;
  }
  
  .consultationImage {
    width: 100%;
    max-height: 400px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .consultationTitle {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #851016;
  }
  
  .consultationDescription {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #000;
  }
  
  /* Unique CSS for General Consultation */
  .consultationDescription::before {
    content: '';
    position: absolute;
    top: 100px;
    right: 30px;
    width: 40%;
    height: 50%;
    background-image: url('../../../../Assets/logos/parkville\ logo.jpg'); /* Replace with your actual image URL */
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 1;
  }
  
  .bookConsultationButton {
    background-color: #851016;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .bookConsultationButton:hover {
    background-color: #6c0d12;
  }
  
  .buttonLink {
    text-decoration: none;
    color: inherit;
  }
  
  /* Media queries for responsiveness */
  @media screen and (min-width: 768px) {
    .consultationPageContainer {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .consultationContent {
      max-width: 45%;
    }
  
    .consultationImageContainer {
      max-width: 45%;
    }
  }
  
  @media screen and (max-width: 767px) {
    .consultationContent,
    .consultationImageContainer {
      max-width: 100%;
    }
  
    .consultationDescription::before {
      width: 90%;
      height: 20%;
      top: 400px;
      right: 5px;
    }
    .consultationTitle {
      font-size: 1.4rem;
      margin-bottom: 10px;
    }
    
    .consultationDescription {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  