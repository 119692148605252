/* ContactBar.css */

.contactBar {
  background-color:#851016;
  position: fixed;
  color: #F2CFD5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 35px;
  width: 100%;
  top: 0px;
  z-index: 999;
}

.contact-info {
  display: flex;
  align-items: center;
}

.phone,
.email {
  margin-right: 20px;
  margin-left: 10px;
  font-size: 14px;
}

.socialIcons {
  display: flex;
  align-items: center;
}

.socialIcons a:hover {
  color: #ECC5C0;
}

.social-icon1 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2px solid  #F2CFD5;
  border-radius: 50%;
}

.social-icon1:hover {
  background-color: #851016;
}

.social-icon1 svg {
  width: 14px;
  height: 14px;
}

.emergencyButton {
  font-size: 14px;
  color: #851016;
  background-color: #F2CFD5;
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
}

.emergency-button:hover {
  background-color: #6a0e12;
}

@media (max-width: 767px) {
.contact-bar {
  padding: 0px 10px;
  font-size: 12px;
  height: 35px;
}
.contact-info {
  margin-left: 5px;
}
.phone,
.email {
  margin-left: 5px;
  font-size: 9px;
}
.socialIcons {
  display: none;
}
.emergencyButton {
  font-size: 12px;
  padding: 2px 5px;

}

}

@media (min-width: 768px) and (max-width: 991px) {
.contact-bar {
  font-size: 10px;
  height: 30px;
}
.social-icon1 {
  font-size: 12px;
  width: 20px;
  height: 20px;
}
.social-icon1 svg {
  width: 10px;
  height: 10px;
}
.emergencyButton {
  font-size: 12px;
  padding: 2px 5px;

}
}
