/* ImagingServicesPage.css */
.imagingPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin-top: 120px;
  }
  
  .imagingContent {
    text-align: justify;
    max-width: 600px;
    margin-bottom: 20px;
  }
  
  .imagingImageContainer {
    width: 100%;
    max-width: 600px;
  }
  
  .imagingImage {
    width: 100%;
    max-height: 400px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .imagingTitle {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #851016;
  }
  
  .imagingDescription {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #000;
  }
  
  /* Unique CSS for Imaging Services */
  .imagingDescription::before {
    content: '';
    position: absolute;
    top: 120px;
    right: 30px;
    width: 40%;
    height: 50%;
    background-image: url('../../../../Assets/logos//parkville\ logo.jpg'); /* Replace with your actual image URL */
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 1;
  }
  
  /* Media queries for responsiveness */
  @media screen and (min-width: 768px) {
    .imagingPageContainer {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .imagingContent {
      max-width: 45%; /* Adjust width for content on medium screens */
    }
  
    .imagingImageContainer {
      max-width: 45%; /* Adjust width for image on medium screens */
    }
  }
  
  @media screen and (max-width: 767px) {
    .imagingContent,
    .imagingImageContainer {
      max-width: 100%; /* Set width to 100% on small screens */
    }
  
    .imagingDescription::before {
      width: 90%;
      height: 20%;
      top: 420px;
      right: 5px;
    }
    .imagingTitle {
      font-size: 1.4rem;
      margin-bottom: 10px;
    }
    
    .imagingDescription {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  