/* LoadTimeIndicator.css */

.load-time-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F2CFD5;
  display: flex;
  flex-direction: column; /* Display spinner and progress bar vertically */
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other content */
}

.spinner {
  width: 100px; /* Adjust the size of the spinner */
  height: 100px;
  animation: spin 2s linear infinite; /* Adjust animation duration and timing function as needed */
}

.progress-bar-container {
  width: 200px; /* Adjust the width of the progress bar container */
  height: 10px; /* Adjust the height of the progress bar */
  background-color: #f0f0f0; /* Background color of the progress bar container */
  border-radius: 5px; /* Border radius for rounded corners */
  margin-top: 20px; /* Adjust the margin between spinner and progress bar */
}

.progress-bar {
  height: 100%;
  background-color: #851016; /* Color of the progress bar */
  border-radius: 5px; /* Border radius for rounded corners */
  transition: width 0.3s ease; /* Smooth transition for width changes */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
