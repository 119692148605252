/* Navbar.css */

.navbar1 {
  position: fixed;
  z-index: 1;
  top: 35px;
  left: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  height: 90px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 5px 5px rgba(242, 207, 213, 0.8);
}

.navbar1.transparent {
  background-color: #ffffff;
  box-shadow: 0 5px 5px rgba(242, 207, 213, 0.8);
}

.logo-container1 {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.home-link {
  text-decoration: none;
  list-style: none;
}
.logo-image {
  width: 200px; /* Adjust the width of the logo image as needed */
  height: 90px; /* Maintain the aspect ratio */
  margin-right: 10px; /* Add some margin to separate the logo from the company name */
  display: none;
}
.company-name {
  font-weight: 600;
  font-size: 2rem;
  color: #851016;
  left: 30px;
}

.hamburger-menu {
  position: fixed;
  top: 60px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  z-index: 9999;
}

.hamburger-menu .line {
  width: 100%;
  height: 2px;
  background-color: #851016;
}

.menu1 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu-item1 {
  margin-right: 10px;
  position: relative;
  padding: 5px 10px;
}

.menu-item1 a {
  color: #851016;
  text-decoration: none;
  padding: 5px 10px;
  transition: color 0.3s;
  font-size: 1.2rem;
  font-weight: 600;
}

.menu-item1 a:hover {
  color: #000000;
}

.submenu1 {
  list-style: none;
  position: absolute;
  background-color: rgba(236, 197, 192, 0.7);
  padding: 0;
  z-index: 2;
  margin-top: 0;
  display: none;
  min-width: 200px;
}

.submenu-open1 {
  display: block;
}

.submenu-item1 {
  margin: 5px;
}

.submenu-item1 a {
  display: block;
  padding: 5px 10px;
  color: #851016;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.submenu-item1 a:hover {
  color: #ffffff;
}

.active2 {
  font-weight: bold;
  color: #000000 !important;
  /* background-color: rgba(236, 197, 192, 0.7); */
}

/* Media Queries */
@media (max-width: 767px) {
  /* Small devices */
  .menu1 {
    display: none;
  }

  .company-name {
    font-size: 18px;
    margin-left: 20px;
    display: none;
  }
  .logo-image {
    width: 200px; /* Adjust the width of the logo image as needed */
    height: 90px; /* Maintain the aspect ratio */
    margin-right: 10px; /* Add some margin to separate the logo from the company name */
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* Medium devices */
  .menu1 {
    display: none;
  }

  .company-name {
    font-size: 20px;
    margin-left: 20px;
    display: none;
  }

  .logo-image {
    width: 200px; /* Adjust the width of the logo image as needed */
    height: 90px; /* Maintain the aspect ratio */
    margin-right: 10px; /* Add some margin to separate the logo from the company name */
    display: flex;
  }
}
