.special-consultation-slider-container5 {
  margin: 20px auto; /* Center the container horizontally */
  max-width: 1200px; /* Set a maximum width for better responsiveness */
}

.service-slide5 {
  text-align: center;
}

.card5 {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  overflow: hidden; /* Ensure content doesn't overflow */
  margin: 20px;
}

.card-image5 {
  width: 100%;
  height: 200px; /* Set a fixed height for uniformity */
  object-fit: cover; /* Maintain aspect ratio */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-content5 {
  padding: 20px;
  background-color: #851016;
}

.card-content5 h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.card-content5 p {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 15px;
}

.book-appointment-btn {
  display: inline-block;
  padding: 5px 10px;
  background-color: #FFD7D7;
  color: #851016;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.book-appointment-btn:hover {
  background-color: #851016;
  color: #FFD7D7;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  outline: none;
  cursor: pointer;
}

.custom-arrow span {
  font-size: 24px;
  color: #333;
  font-weight: 600;
}

.custom-prev-arrow {
  left: 0;
}

.custom-next-arrow {
  right: 0;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .card-image5 {
    height: 150px; /* Adjust image height for smaller screens */
  }

  .card-content5 h3 {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .card-content5 {
    padding: 10px;
  }
  
  .card-content5 p {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
}
