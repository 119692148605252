/* WhatsAppButton.css */
.whatsappButton {
  position: fixed;
  display: flex;
  align-items: center;
  background-color:  #851016;
  color: #fff;
  padding: 10px;
  border-radius: 10%; /* Use 50% for a hard rounded border */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add box shadow */
  cursor: pointer;
  bottom: 5px;
  right: 10px;
  z-index: 1;
}


.whatsappIcon2 {
  border: none;
  cursor: pointer;
  font-size: 14px;
  background-color:  #851016;
}

.whatsappIcon2 img {
  width: 20px; /* Adjust this to fit your icon size */
  height: 20px; /* Adjust this to fit your icon size */
  vertical-align: middle;
}

.whatsappText2 {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color:#25D366; /* Change this to your preferred text color */
  cursor: pointer;
}
