.testimonial-slider-container {
    margin: 40px;
    border: 2px solid #851016;
    border-radius: 10px;
    padding: 10px;
    background-color: #FFD7D7;
  }
  
  .testimonialsTitle {
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #851016;
  }
  
  .testimonial-slide {
    text-align: center;
  }
  
  .profile-image {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
    line-height: 60px;
    color: white;
    background-color: #333;
    margin-bottom: 20px;
  }
  
  .comment {
    font-style: italic;
    color: #777;
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .client-name {
    font-weight: bold;
    margin-top: 10px;
    font-size: 1.2rem;
    color: #851016;
  }
  
  .slick-prev,
  .slick-next {
    font-size: 24px;
    color: #333;
    border: none;
    background: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 40px;
  }
  
  .slick-prev {
    left: 0;
  }
  
  .slick-next {
    right: 0;
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    color: #555;
  }
  
  /* Quotes */
  .quote {
    font-size: 36px;
    color: #FF6F61;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .testimonial-slider-container {
      margin: 20px;
      padding: 5px;
    }
    .testimonialsTitle {
      font-size: 1rem;
      margin-bottom: 10px;
    }
  
    .profile-image {
      width: 50px;
      height: 50px;
      font-size: 22px;
      line-height: 50px;
    }
  
    .comment {
      font-size: 0.8rem;
    }
    .client-name {
        font-size: 1rem;
      }
  
    .slick-prev,
    .slick-next {
      font-size: 14px;
      margin: 10px;
    }
  }
  