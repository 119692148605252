/* WhatsAppChatPopup.css */
.whatsappChatPopup {
    display: none;
    position: fixed;
    bottom: 90px;
    right: 40px;
    width: 300px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .whatsappChatPopup.open {
    display: block;
   
  }
  
  .chat-header {
    padding: 5px;
    border-bottom: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-color: #128C7E;
    z-index: 999;
  }

  .header-logo-container {
    display: flex;
    align-items: flex-end; /* Align the items to the bottom */

  }
  
  .header-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%; /* Make it a circle */
    overflow: hidden; /* Hide the overflow to keep the circle shape */
    margin-right: 10px;
  }
  
  .online-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #4caf50;
    left: 0%;
    margin-bottom: 5px; /* Add margin to position it at the bottom */
  }
  
  
  .ChatheaderText {
    flex-grow: 1;
  }
  .ChatheaderText h1 {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
  }

  .ChatheaderText p {
    font-size: 10px;
    font-weight: 100;
    color: #fff;
  }
  
  .ChartcloseButton{
    background-color: #128C7E;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
.chat-body {
    padding: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .reply {
    background-color: #dcf8c6; /* Light green background for replies */
    padding: 8px;
    border-radius: 10px;
    margin-bottom: 5px;
  }
  
  .reply p {
    margin: 0;
    font-size: 14px;
    color: #000; /* Adjust the text color */
  }
  
  .chat-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .startChatButton {
    background-color: #25D366;
    color: #fff;
    border: none;
    width: 100%;
    padding: 10px;
    border-radius: 1px;
    border-radius: 10%; /* Use 50% for a hard rounded border */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add box shadow */
    cursor: pointer;
    align-items: center;
  }

  /* Add media queries for responsiveness */
@media only screen and (max-width: 600px) {
    .whatsappChatPopup {
      width: 90%;
      right: 0;
      border-radius: 0;
    }
  }
