/* PediatricPage.css */
.pediatricPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin-top: 120px;
  }
  
  .pediatricContent {
    text-align: justify;
    max-width: 600px;
    margin-bottom: 20px;
  }
  
  .pediatricImageContainer {
    width: 100%;
    max-width: 600px;
  }
  
  .pediatricImage {
    width: 80%;
    max-height: 600px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-left: 50px;
  }
  
  .pediatricTitle {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #851016;
  }
  
  .pediatricDescription {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #000;
  }
  
  /* Media queries for responsiveness */
  @media screen and (min-width: 768px) {
    .pediatricPageContainer {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .pediatricContent {
      max-width: 45%; /* Adjust width for content on medium screens */
    }
  
    .pediatricImageContainer {
      max-width: 45%; /* Adjust width for image on medium screens */
    }
  }
  
  @media screen and (max-width: 767px) {
    .pediatricContent,
    .pediatricImageContainer {
      max-width: 100%; /* Set width to 100% on small screens */
    }
    .pediatricTitle {
      font-size: 1.4rem;
      margin-bottom: 10px;;
    }
    
    .pediatricDescription {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  