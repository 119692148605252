/* PhotoGallery.css */

.photoGalleryContainer2 {
    width: 70%;
    padding: 20px;
    position: relative;
    margin-top: 120px;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    background-color: #851016;
    height: 100vh;
    margin-bottom: 20px;

  }
  
  .carousel-arrow {
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    color: #333;
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 20px;
    z-index: 1;
  }
  
  .carousel-arrow:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .prev {
    left: 20px;
  }
  
  .next {
    right: 20px;
  }
  
  .carousel-container {
    position: relative;
    overflow: hidden;
  }
  
  .carousel {
    position: relative;
  }
  
  .carousel-slide {
    display: flex;
    transition: transform 0.3s ease;
  }
  
  
  .galleryImage2 {
    width: 50%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    height: 90vh;
  }
  
  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 14px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item:hover .caption {
    opacity: 1;
  }
  
  /* Media queries for responsiveness */
  @media screen and (max-width: 768px) {
    .photoGalleryContainer2{
        width: 90%;
        top: 10px;
        height: 65vh;
        margin-bottom: 20px;
        margin-left: 0;
    
      }
      .galleryImage2 {
        width: 100%;
        height: 60vh;

      }
  
    .prev,
    .next {
      font-size: 16px;
      padding: 6px;
    }
  
    .caption {
      font-size: 12px;
    }
    .carousel-arrow {
      top: 30vh;
      font-size: 20px;
    }
  }
  